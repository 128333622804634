
import { defineComponent } from "vue";
import AppPage from "@/components/AppPage.vue";
import moment, { Moment } from "moment-timezone";
import { Pagination, Sorting } from "@/use/types";
import DataTable from "@/components/DataTable.vue";
import TextInput from "@/components/TextInput.vue";
import Daterange from "@/components/Daterange.vue";
import SubsetInput from "@/components/SubsetInput.vue";
import axios from "axios";

interface IDataModel {
  updating: boolean;
  loading: boolean;
  filter: IQuery;
  rows: any[];
  totalRows: number;
  columns: Column[];
  definition: any;
  options: {
    affiliates: any[];
  },
  statuses: any[];
  pendingPayouts: any[];
}

interface IQuery {
  period: Moment[];
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
  affiliate_ids: [];
  status_ids: [];
  pending: boolean;
}

interface Column {
  id: number | string;
  name: string;
  selected: boolean;
  freezed?: boolean;
}

export function getDefaultQuery(): IQuery {
  return {
    period: [],
    search: "",
    sorting: { column: "created_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
    affiliate_ids: [],
    status_ids: [],
    pending: false,
  };
}

const dataColumns = [
  {
    id: "created_at",
    name: "Дата",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "affiliate_id",
    name: "id веба",
    selected: true,
    sortable: true,
  },
  {
    id: "transaction_number",
    name: "№ транзакции",
    selected: true,
    sortable: true,
    type: "text",
  },
  {
    id: "withdrawal_method",
    name: "способ вывода",
    selected: true,
    sortable: true,
    type: "text",
  },
  {
    id: "withdrawal_amount",
    name: "сумма вывода",
    selected: true,
    sortable: true,
  },
  {
    id: "status",
    name: "статус",
    selected: true,
    sortable: true,
    type: "text",
  },
  {
    id: "balance",
    name: "остаток на балансе",
    selected: true,
    sortable: true,
  },
];

export default defineComponent({
  components: {
    AppPage,
    DataTable,
    TextInput,
    Daterange,
    SubsetInput,
  },
  data(): IDataModel {
    return {
      updating: false,
      loading: false,
      filter: getDefaultQuery(),
      rows: [],
      totalRows: 0,
      columns: dataColumns,
      definition: {
        shortcut: null,
        timezone: "Europe/Kiev",
      },
      options: {
        affiliates: [],
      },
      statuses: [],
      pendingPayouts: [],
    };
  },
  async created() {
    this.load();
    this.getStatuses();
    this.getAffiliates();
    await this.getPeriodLimits();
  },
  mounted: function () {
    this.$watch("filter.pending", this.load);
    this.$watch("filter.pagination", this.load);
    this.$watch("filter.sorting", this.load);
  },
  methods: {
    async filterOptions(collection: string, query = "", field?: string) {
      const { data } = await axios.get(
        "/api/" + (field || collection) + "/lookup",
        {
          params: { q: JSON.stringify({ query }) },
        }
      );
      const key: "affiliates" = collection as any;
      this.options[key] = data;
    },
    async getStatuses() {
      const { data } = await axios.get("/api/payouts/statuses");
      this.statuses = data;
    },
    async getAffiliates() {
      const { data } = await axios.get("/api/affiliates/lookup");
      this.options.affiliates = data;
    },
    updateStatuses(e: any) {
      this.filter.status_ids = e;
    },
    updateAffiliateIds(e: any) {
      this.filter.affiliate_ids = e;
    },
    async updatePayout(token: string, status: string) {
      this.updating = true;
      try {
        await axios.post("/api/payouts/process", {
          token,
          status,
        });
        this.updating = false;
        this.load();
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
    },
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    async getPeriodLimits() {
      const { data } = await axios("/api/get-period-limits/payouts");
      this.filter.period = [data.min, moment().endOf("day")];
    },
    async setPeriod(value: any) {
      if (!value.length) {
        await this.getPeriodLimits();
        return;
      }
      this.filter.period = value;
    },
    async toPending() {
      this.filter.pending = true;
    },
    async toAll() {
      await this.getPeriodLimits();
      this.filter.pending = false;
    },
    async clear() {
      this.filter = getDefaultQuery();
      await this.getPeriodLimits();
    },
    async load() {
      this.loading = true;
      this.rows = [];
      this.totalRows = 0;

      try {
        const [{ data: rows }, { data: pengingPayouts }] = await Promise.all([
          await axios.get("/api/payouts", {
            params: { q: JSON.stringify(this.filter) },
          }),
          await axios.get("/api/payouts/get-pending"),
        ]);
        this.rows = rows.rows;
        this.totalRows = rows.total;
        this.pendingPayouts = pengingPayouts;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
  },
});
